.footer-digidzn{
    background-color: black;
}

.footer-area-1 span{
color: white;
font-size:15px;
}
.footer-area-1 link{
    margin-top: 30px;
}
.link-mt{
    margin-top:20px ;
}
.mt-10{
    margin-top: 10%;
  }
  .footer-links{
    color:#929292;
    text-decoration: none;
    /* padding: 0.5re; */
    font-size:13px;
    
}
.alignment-foot-logo{
    display: flex;
    margin-left: -9px;
    justify-content: flex-start;
    align-items: center!important;
}
.footer-links-heading{
    font-size: 16px;
    color: #fff;
}
.footer-menu{
    list-style: none;
    margin-left: 40px;
}
ul.inline-icon li{
    display: inline;
    padding:8px;
    
    font-size: 1.5rem;
    color: #9AA0A7;
}
.footer-icon{
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.footer-bottom-text{
    padding: 40px 0 20px 30px;
}
.footer-bottom-text p{
    font-size: 13px;
    color: #929292;
}
.footer-icon-link{
    color: #9AA0A7;
}
@media only screen and (max-width: 960px){
    .footer-menu{
        margin-left: 0px;
    }
    .mob-dis-col{
        display: none;
    }
    .footer-bottom-text {
        padding: 11px 0 12px 30px;
    }
}